$primary: #5e72e4;
$grey: #ecedef;
$black: #343434;

.ticket {
  & + .ticket {
    margin-top: 25px;
  }

  .cardWrap {
    display: flex;
    flex-direction: row;
    color: #fff;
    font-family: sans-serif;
  }

  .cardTicket {
    background: linear-gradient(to bottom, $primary 0%, $primary 26%, $grey 26%, $grey 100%);
    height: 11em;
    // float: left;
    position: relative;
    padding: 1em;
  }

  .cardLeft {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    flex: auto;
  }

  .cardRight {
    flex: 1;
    border-left: .18em dashed #fff;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    &:before,
    &:after {
      content: "";
      position: absolute;
      display: block;
      width: .9em;
      height: .9em;
      background: #fff;
      border-radius: 50%;
      left: -.5em;
    }
    &:before {
      top: -.4em;
    }
    &:after {
    bottom: -.4em;
    }
  }

  h1 {
    font-size: 1.1em;
    margin-top: -5px;
    color: $grey;
    span {
      font-weight: normal;
    }
  }

  .title, .name, .seat, .time {
    text-transform: uppercase;
    font-weight: normal;
    h2 {
      font-size: .9em;
      color: #525252;
      margin: 0;
    }
    span {
      font-size: .7em;
      color: #a2aeae;
    }
  }

  .title {
    margin: 1.5em 0 0 0;
  }

  .name, .seat {
    margin: .7em 0 0 0;
  }

  .time {
    margin: .7em 0 0 1em;
  }

  .seat, .time {
    float: left;
  }

  .eye {
    position: relative;
    width: 2em;
    height: 1.5em;
    background: #fff;
    margin: 0 auto;
    margin-top: -5px;
    color: $grey;
    border-radius: 1em/0.6em;
    z-index: 1;
    &:before, &:after {
      content:"";
      display: block;
      position: absolute;
      border-radius: 50%;
    }
    &:before {
      width: 1em;
      height: 1em;
      background: $primary;
      z-index: 2;
      left: 8px;
      top: 4px;
    }
    &:after {
    width: .5em;
    height: .5em;
    background: #fff;
    z-index: 3;
    left: 12px;
    top: 8px;
    }
  }

  .number {
    text-align: center;
    text-transform: uppercase;
    h3 {
      color: $primary;
      margin: 4em 0 0 0;
      font-size: 1em;

    }
    span {
      display: block;
      color: #a2aeae;
    }
  }
}
